import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  profile: {},
  appData: {},
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return updateObject(state, { profile: data });

    case actionTypes.APPLICATION_DETAILS_RESPONSE:
      return updateObject(state, { appData: data });
    default:
      return state;
  }
};
