import * as actionTypes from "../actions/actionTypes";

export default (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.PUSH_BUSINESS_DETAIL:
      return data;
    default:
      return state;
  }
};
