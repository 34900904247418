import React, { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import MainLoader from "../shared/component/mainLoader";
// import SmartBanner from "react-smartbanner";
// import "../assets/smartbanner.css";

const SignUp = React.lazy(() => import("../pages/SignUp"));
const SignIn = React.lazy(() => import("../pages/SignIn"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const ForgotPassword = React.lazy(() => import("../pages/ForgetPassword"));
const Home = React.lazy(() => import("./Home"));
const verifyOTP = React.lazy(() => import("../pages/VerifyOTP/VerifyOTP"))

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route
          exact
          path={"/set-password/:otp/:email"}
          component={ResetPassword}
        />
        <Route exact path="/verifyOTP" component={verifyOTP} />
        <Route exact path="/set-password" component={ResetPassword} />
        <Route exact path="/reset-password" component={ForgotPassword} />
        <Route path="" component={Home} />
        <Redirect exact path={"*"} to={""} />
      </Switch>
    );

    return (
      <div>
        {/*<SmartBanner
            daysHidden={0} // days to hide banner after close button is clicked (defaults to 15)
            daysReminder={1} // days to hide banner after "VIEW" button is clicked (defaults to 90)
            appStoreLanguage="us" // language code for the App Store (defaults to user's browser language)
            title="Oppvenuz"
            author="Oppvenuz"
            button="Download"
            price={{
            ios: "FREE",
            android: "FREE",
            }}
            ignoreIosVersion
            />*/}
        <Router>
          <Suspense
            fallback={
              <div>
                <MainLoader className="main-spinner-position" />
              </div>
            }
          >
            {routes}
          </Suspense>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  if (state.user.profile.isSocialMediaLogin === 'false' ? state.user.profile.user : state.user.profile) {
    try {
      return {
        isAuthenticated: state.user.profile.token.access ? true : false,
        user: state.user.profile.isSocialMediaLogin === 'false' ? state.user.profile.user : state.user.profile,
      };
    } catch (e) {
      return {
        isAuthenticated: false,
        user: state.user.profile.isSocialMediaLogin === 'false' ? state.user.profile.user : state.user.profile,
      };
    }
  }
};
export default withRouter(connect(mapStateToProps)(App));
