import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./override.css";
import Root from "./container/Root";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

// ReactDOM.render(
//   <React.StrictMode>
//   <Root />,
//   </React.StrictMode>,
//   document.getElementById("root")
// );

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
