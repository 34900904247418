import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";

export function* getServiceListCall(action) {
  try {
    const response = yield call(serviceCreator.serviceListService);
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        if (response.data.data.length > 0) {
          response.data.data.sort(function (a, b) {
            if (a.service_type < b.service_type) {
              return -1;
            }
            if (a.service_type > b.service_type) {
              return 1;
            }
            return 0;
          });
        }
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.serviceListResponse(response.data.data));
      } else {
        yield put(actionCreators.toggleMainLoader(false));
        // toastr.light("", "", ToasterAction.toastrErrorOptions);
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* getSimilarCategoryListCall(action) {
  
  try {
    const response = yield call(
      serviceCreator.similarCateoryService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
     
        yield put(actionCreators.toggleMainLoader(false));
        
        yield put(actionCreators.similarCategoryListResponse(response.data));
        
      
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* getCommentListCall(action) {
  try {
    const response = yield call(
      serviceCreator.commentListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {

     
        yield put(actionCreators.toggleMainLoader(false));
        
        yield put(actionCreators.commentListResponse(response.data.data));
        
      
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}


export function* getCityListCall(action) {
  try {
    const response = yield call(serviceCreator.cityListService, action.state);

    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        //yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.cityListResponse(response.data.data));
      }
    }
  } catch (error) {
    // yield put(actionCreators.toggleMainLoader(false));
    toast.error("Internal server error", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
}

export function* getStateListCall(action) {
  try {
    const response = yield call(serviceCreator.stateListService);

    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        //yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.stateListResponse(response.data.data));
      }
    }
  } catch (error) {
    // yield put(actionCreators.toggleMainLoader(false));
    toast.error("Internal server error", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
}

export function* getInviteListCall(action) {
  try {
    const response = yield call(serviceCreator.inviteListService);
    //console.log(response.data);

    //if (response.status === 200 || response.status === 201) {
    //if (
    //  response.data.status_code === 200 ||
    //  response.data.status_code === 201
    //) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.inviteListResponse(response));
    //}
    //}
  } catch (error) {
    // yield put(actionCreators.toggleMainLoader(false));
    toast.error("Internal server error", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
}

export function* getNextInviteListCall(action) {
  try {
    let data = action.data.allData;
    let response = yield call(serviceCreator.inviteNextListService, action);
    response.data = [...response.data, ...data];
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.inviteListResponse(response));
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    toast.error("Internal server error", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
}

export function* businessListCall(action) {
  try {
    yield put(actionCreators.toggleMainLoader(true));
    const response = yield call(
      serviceCreator.businessListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
      
        if (response.data.data !== null) {
          yield put(actionCreators.businessListResponse(response.data.data));
          yield put(actionCreators.toggleMainLoader(false));
          // response.data.data.results = response.data.data.results.map(item => {
          //   if (
          //     item.service_pricing !== null &&
          //     item.service_pricing.length > 0
          //   ) {
          //     item.service_pricing.sort(function (a, b) {
          //       if (a.discounted_price < b.discounted_price) {
          //         return -1;
          //       }
          //       if (a.discounted_price > b.discounted_price) {
          //         return 1;
          //       }
          //       return 0;
          //     });

          //     return item;
          //   } else {
          //     return item;
          //   }
          // });
        }
        
      } else {
        yield put(actionCreators.toggleMainLoader(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
