import * as ActionTypes from "./actionTypes";

export const getArticleList = data => ({
  type: ActionTypes.GET_ARTICLE_LIST,
  data,
});

export const articleListResponse = data => ({
  type: ActionTypes.ARTICLE_LIST_RESPONSE,
  data,
});

export const getArticleItem = data => ({
  type: ActionTypes.ARTICLE_ITEM_DETAIL,
  data,
});

export const searchArticleItem = (data, payload) => ({
  type: ActionTypes.SEARCH_ARTICLE,
  data,
  payload,
});
