// UsersActions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_APPLICATION_DETAILS = "GET_APPLICATION_DETAILS";

export const APPLICATION_DETAILS_RESPONSE = "APPLICATION_DETAILS_RESPONSE";

// article actions

export const GET_ARTICLE_LIST = "GET_ARTICLE_LIST";

export const ARTICLE_LIST_RESPONSE = "ARTICLE_LIST_RESPONSE";

export const ARTICLE_ITEM_DETAIL = "ARTICLE_ITEM_DETAIL";

export const SEARCH_ARTICLE = "SEARCH_ARTICLE";

export const SDK_RESPONSE = "SDK_RESPONSE"

// service actions

export const GET_SERVICE_LIST = "GET_SERVICE_LIST";

export const GET_SIMILAR_CATEGORY_LIST = "GET_SIMILAR_CATEGORY_LIST";

export const SERVICE_LIST_RESPONSE = "SERVICE_LIST_RESPONSE";

export const GET_COMMENT_LIST = "GET_COMMENT_LIST";

export const COMMENT_LIST_RESPONSE = "COMMENT_LIST_RESPONSE";

export const GET_CITY_LIST = "GET_CITY_LIST";

export const GET_STATE_LIST = "GET_STATE_LIST";

export const GET_INVITE_LIST = "GET_INVITE_LIST";

export const GET_NEXT_INVITE_LIST = "GET_NEXT_INVITE_LIST";

export const CITY_LIST_RESPONSE = "CITY_LIST_RESPONSE";

export const STATE_LIST_RESPONSE = "STATE_LIST_RESPONSE";

export const INVITE_LIST_RESPONSE = "INVITE_LIST_RESPONSE";

// loader actions

export const SHOW_OR_HIDE_MAIN_LOADER = "SHOW_OR_HIDE_MAIN_LOADER";

export const SHOW_OR_HIDE_MAIN_SEARCH_LOADER =
  "SHOW_OR_HIDE_MAIN_SEARCH_LOADER";

export const SHOW_OR_HIDE_SECOND_LOADER = "SHOW_OR_HIDE_SECOND_LOADER";

// business actions

export const GET_BUSINESS_LIST = "GET_BUSINESS_LIST";

export const BUSINESS_LIST_RESPONSE = "BUSINESS_LIST_RESPONSE";

export const SIMILAR_CATEGORY_LIST_RESPONSE = "SIMILAR_CATEGORY_LIST_RESPONSE";

export const PUSH_BUSINESS_DETAIL = "PUSH_BUSINESS_DETAIL";

//cart actions

export const GET_CART_ITEMS = "GET_CART_ITEMS";

export const CART_ITEMS_RESPONSE = "CART_ITEMS_RESPONSE";

export const DELETE_CART_ITEMS = "DELETE_CART_ITEMS";

//notification actions

export const GET_NOTIFICATION = "GET_NOTIFICATION";

export const NOTIFICATION_RESPONSE = "NOTIFICATION_RESPONSE";

// unAuthenticated permission actions

export const ADD_NEXT_URL_ACCESS = "ADD_NEXT_URL_ACCESS";

// E-invites user card actions

export const GET_USER_CARDS_LIST = "GET_USER_CARDS_LIST";

export const USER_CARDS_LIST_RESPONSE = "USER_CARDS_LIST_RESPONSE";

export const SEARCH_USER_CARD = "SEARCH_USER_CARD";
