import axios from "../../axios";
import * as url from "../../utils/url";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `${process.env.REACT_APP_EINVITE_BEARER_TOKEN}`,
};

export const articleListService = data => {
  return axios.post(`${url.getArtistList}?page=${data.pagination}`, {
    user_role: "USER",
    user_id: data.user_id,
  });
};

export const serviceListService = () => {
  return axios.get(url.serviceList, null);
};

export const cityListService = state => {
  return axios.get(url.cityList, { params: { state_name: state } });
};

export const stateListService = state => {
  return axios.get(url.stateList, null);
};

export const inviteListService = state => {
  return fetch(url.allEinvites, {
    headers,
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => {
      throw error;
    });
};

export const inviteNextListService = data => {
  return fetch(`${url.allEinvites}&cursor=${data.data.token}`, {
    headers,
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => {
      console.log({error})
      throw error;
    });
};

export const userInviteListService = data => {
  return axios.post(
    `${url.allUserEinvites(data?.user_id)}&page=${data.pagination}`
  );
};

export const userSingleInviteService = template_id => {
  return axios.get(url.singleUserInvite(template_id));
};

export const addInviteService = params => {
  return axios.post(url.addInvite, params);
};

export const userInviteTemplateSearchService = (user_id, searchVal) => {
  return axios.post(url.userInviteTemplateSearch(user_id, searchVal));
};

export const userProfileService = params => {
  return axios.post(url.getUserProfileDetail, params);
};

export const businessListService = data => {
  let payload = null;
  if (data.user_id !== null) {
    payload = { user_id: data.user_id };
  }
  if (typeof data.search !== "undefined" && data.search.length > 0) {
    return axios.get(
      `${url.findService}${data.serviceType}?search=${data.search}&city=${data.city}&user_group=${data.user_group}`,
      payload
    );
  } else {
    return axios.get(
      `${url.businessList}${data.serviceType}?city=${data.city}&page=${data.pagination}&user_group=${data.user_group}`,
      payload
    );
  }
};

export const searchArticleService = (data, payload) => {
  return axios.post(url.searchArticle + data, payload);
};

export const cartItemsService = data => {
  return axios.get(`${url.getCartItems}/${data.user_id}`, null);
};

export const deleteCartItemService = data => {
  return axios.delete(url.deleteCartItem + data, null);
};

export const getNotificationService = () => {
  return axios.get(url.getNotification, null);
};

export const getApplicationDataService = () => {
  return axios.get(url.getApplicationData, null);
};

export const allCategoriesService = () => {
  return axios.post(url.getAllCategories, null);
};

export const allCategoryPinsService = (order, searchValue) => {
  return axios.post(url.getAllCategoriesPins(order, searchValue));
};

export const boardPinListService = (order, searchValue, payload) => {
  return axios.post(url.getBoardPinList(order, searchValue), payload);
};

export const similarCateoryService = data => {
  return axios.get(url.similarCategory + data, null);
};

export const commentListService = (data) => {
  let comment_url = `${url.listReview}/${data.id}?${data.rating !== '' ? "rating="+data.rating : ''}`;
  
  return axios.get(comment_url, null);
};