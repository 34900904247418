import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  links: {},
  count: 0,
  results: [],
  sdkLoaded: false,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.ARTICLE_LIST_RESPONSE:
      return updateObject(state, {
        links: data.links,
        count: data.count,
        results: [...data.results],
      });
    case actionTypes.SDK_RESPONSE:
      return updateObject(state, {
        sdkLoaded: true,
      }); 
    default:
      return state;
  }
};
