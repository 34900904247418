import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    links: {},
    count: 0,
    results: [],
  };

export default (state = initialState, { type, data }) => {
  switch (type) {
      
      case actionTypes.COMMENT_LIST_RESPONSE:
      return updateObject(state, {
        links: data.links,
        count: data.count,
        results: [...data.results]
      });

    default:
      return state;
  }
};