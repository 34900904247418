import * as ActionTypes from "./actionTypes";

export const getCartItems = data => ({
  type: ActionTypes.GET_CART_ITEMS,
  data,
});

export const cartItemsResponse = data => ({
  type: ActionTypes.CART_ITEMS_RESPONSE,
  data,
});

export const deleteCartItem = data => ({
  type: ActionTypes.DELETE_CART_ITEMS,
  data,
});
