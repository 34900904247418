import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "../utils/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const Root = props => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <ToastContainer hideProgressBar={true} newestOnTop={false} />
      </BrowserRouter>
    </Provider>
  );
};

export default Root;
