import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
// import { toast } from "react-toastify";

export function* getArticleSagaCall(action) {
  try {
    const response = yield call(serviceCreator.articleListService, action.data);
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.articleListResponse(response.data.data));
      } else {
        yield put(actionCreators.toggleMainLoader(false));
        // toastr.light("", "", ToasterAction.toastrErrorOptions);
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    // console.log(getArticleSagaCall);
  }
}

export function* searchArticleSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.searchArticleService,
      action.data,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleSearchLoader(false));
        yield put(actionCreators.articleListResponse(response.data.data));
      } else {
        yield put(actionCreators.toggleSearchLoader(false));
        // toastr.light("", "", ToasterAction.toastrErrorOptions);
      }
    } else {
      yield put(actionCreators.toggleSearchLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSearchLoader(false));
  }
}
