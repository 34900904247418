import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";

export function* getUserCardsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.userInviteListService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.userCardListResponse(response.data.data));
      } else {
        yield put(actionCreators.toggleMainLoader(false));
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* searchUserCardSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.userInviteTemplateSearchService,
      action.data,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleSearchLoader(false));
      yield put(actionCreators.userCardListResponse(response.data.data));
    } else {
      yield put(actionCreators.toggleSearchLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSearchLoader(false));
  }
}
