const REDUX_STATE = "state";
const AUTHERIZAION_TOKEN = "AutherizationToken";
const SIDE_BAR_STATE = "sideBarState";
const REFRESH_TOKEN = "refreshToken";
const CLIENT_ID = "clientId";
const CLIENT_SECRET = "clientSecret";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(REDUX_STATE, serializedState);
  } catch (err) {
    //log this
  }
};

export const saveToken = token => {
  try {
    localStorage.setItem(AUTHERIZAION_TOKEN, token?.access);
    localStorage.setItem(REFRESH_TOKEN, token?.refresh);
  } catch (err) {
    console.log(err);
  }
};

export const saveMobileToken = (token, refresh) => {
  try {
    localStorage.setItem(AUTHERIZAION_TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refresh);
  } catch (err) {
    console.log(err);
  }
};

export const saveAppData = data => {
  try {
    localStorage.setItem(CLIENT_ID, data.client_id);
    localStorage.setItem(CLIENT_SECRET, data.client_secret);
  } catch (err) {
    console.log(err);
  }
};

export const retrieveClientId = () => {
  let client_id = localStorage.getItem(CLIENT_ID);
  return client_id;
};

export const retrieveClientSecret = () => {
  let client_secret = localStorage.getItem(CLIENT_SECRET);
  return client_secret;
};

export const retrieveRefreshToken = () => {
  let token = localStorage.getItem(REFRESH_TOKEN);
  return !token || token == null || token === "" ? "sdjnsc" : token;
};

export const retrieveToken = () => {
  let token = localStorage.getItem(AUTHERIZAION_TOKEN);
  return !token || token == null || token === "" ? "sdjnsc" : token;
};

export const saveSideBarState = sideBarState => {
  try {
    localStorage.setItem(SIDE_BAR_STATE, sideBarState);
  } catch (err) {
    //log this
  }
};
