import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import MainSpinner from "../../assets/mainLoader.svg";

const MainLoader = ({ className }) => {
  return (
    <React.Fragment>
      <Row className={className}>
        <Col
          lg={{ size: 4, offset: 4 }}
          md={{ size: 4, offset: 4 }}
          className="align-center"
        >
          <div>
            <img src={MainSpinner} alt="" width="60px" />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

MainLoader.propTypes = {
  className: PropTypes.string,
};

MainLoader.defaultProps = {
  className: undefined,
};

export default MainLoader;
