import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  serviceList: [],
  cityList: [],
  stateList: [],
  inviteList: [],
  nextURL:""
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SERVICE_LIST_RESPONSE:
      return updateObject(state, { serviceList: [...data] });
    case actionTypes.CITY_LIST_RESPONSE:
      return updateObject(state, { cityList: [...data] });
    case actionTypes.STATE_LIST_RESPONSE:
      return updateObject(state, { stateList: [...data] });
    case actionTypes.INVITE_LIST_RESPONSE:
      return updateObject(state, { inviteList: [...data.data], nextURL: data.links.next || "" });
    default:
      return state;
  }
};
