import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";
import user from "./user";
import articles from "./articles";
import articleItem from "./articleItem";
import serviceList from "./serviceList";
import loader from "./loader";
import businessList from "./businessList";
import businessDetail from "./businessDetail";
import cartItems from "./cartItems";
import notifications from "./notification";
import userCardsList from "./userCardsList";
import nextLink from "./nextLink";
import similarCategoryList from "./similarCategoryList";
import commentList from "./commentList";

const appReducer = combineReducers({
  user: user,
  articles: articles,
  articleItem: articleItem,
  serviceList: serviceList,
  businessList: businessList,
  businessDetail: businessDetail,
  loader: loader,
  cartItems: cartItems,
  notifications: notifications,
  userCardsList: userCardsList,
  nextLink: nextLink,
  similarCategoryList: similarCategoryList,
  commentListResponse: commentList
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default rootReducer;
