import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { toast } from "react-toastify";
import { saveAppData } from "../../utils/localStorage";

export function* getApplicationDataCall(action) {
  try {
    const response = yield call(serviceCreator.getApplicationDataService);

    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        saveAppData(response.data.data);
        yield put(
          actionCreators.applicationDetailsResponse(response.data.data)
        );
      }
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.data &&
      typeof error.response.data === "object"
    ) {
      toast.error(error.response.data.message["0"], {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error("500 Internal Server Error", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
}
