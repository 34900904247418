import * as actionTypes from "../actions/actionTypes";

export default (state = {}, { type, data }) => {

  switch (type) {
    case actionTypes.CART_ITEMS_RESPONSE:
      return data;
    default:
      return state;
  }
};