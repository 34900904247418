import * as actionTypes from "../actions/actionTypes";

export default (state = { next: "",data:{} }, { type, data }) => {
  switch (type) {
    case actionTypes.ADD_NEXT_URL_ACCESS:
      return data;
    default:
      return state;
  }
};
