import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";

export function* getCartItemsCall(action) {
  try {
    const response = yield call(serviceCreator.cartItemsService, action.data);

    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleSecondLoader(false));
        yield put(actionCreators.cartItemsResponse(response.data.data));
      } else {
        yield put(actionCreators.toggleSecondLoader(false));
        // toastr.light("", "", ToasterAction.toastrErrorOptions);
      }
    } else {
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSecondLoader(false));
  }
}

export function* deleteCartItemCall(action) {
  try {
    const response = yield call(
      serviceCreator.deleteCartItemService,
      action.data
    );
    console.log(response);
    if (response.status === 200 || response.status === 201) {
      if (
        response.data.status_code === 200 ||
        response.data.status_code === 201
      ) {
        yield put(actionCreators.toggleMainLoader(false));
        yield put(actionCreators.cartItemsResponse(response.data.data));
      } else {
        yield put(actionCreators.toggleMainLoader(false));
        // toastr.light("", "", ToasterAction.toastrErrorOptions);
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
