import axios from "axios";
import {
  retrieveToken,
  retrieveRefreshToken,
  retrieveClientId,
  retrieveClientSecret,
  saveToken,
} from "./utils/localStorage";
import * as urls from "./utils/url";
export const PROD = "PRODUCTION";

export const QA = "QA";
export const DEV = "DEVELOPMENT";
export let ENV;
export const LOCAL = "LOCAL";

axios.defaults.headers.post["Content-Type"] = "application/json";
const instance = axios.create({
  baseURL: `${window.location.protocol}//`,
});

instance.all = axios.all;
instance.spread = axios.spread;

axios.interceptors.request.use(
  config => {
    const token = `Bearer ${retrieveToken()}`;
    config.headers.Authorization = token;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.request.use(
  config => {
    const token = `Bearer ${retrieveToken()}`;
    config.headers.Authorization = token;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const loginSocial = JSON.parse(localStorage.getItem("state"));
    const socialMediaLogin = loginSocial?.user?.profile?.isSocialMediaLogin;

    if (
      error.response.status === 401 &&
      originalRequest.url === urls.manualGenerateToken &&
      socialMediaLogin == "false"
    ) {
      localStorage.clear();
      window.location.pathname = "/login";
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === urls.generateToken &&
      socialMediaLogin == "true"
    ) {
      localStorage.clear();
      window.location.pathname = "/login";
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      socialMediaLogin == "true"
    ) {
      originalRequest._retry = true;
      let payload = {
        refresh_token: retrieveRefreshToken(),
        client_id: retrieveClientId(),
        client_secret: retrieveClientSecret(),
        grant_type: "refresh_token",
      };

      return axios
        .post(urls.generateToken, payload)
        .then(res => {
          if (res?.status === 201 || res?.status === 200) {
            saveToken({
              access: res.data.access_token,
              refresh: res.data.refresh_token,
            });

            instance.defaults.headers.common["Authorization"] =
              "Bearer " + retrieveToken();

            return instance(originalRequest);
          } else {
            localStorage.clear();
            window.location.pathname = "/login";
          }
        })
        .catch(error => {
          //localStorage.clear();
          //window.location.pathname = "/login";
        });
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      socialMediaLogin == "false"
    ) {
      originalRequest._retry = true;
      return axios
        .get(urls.manualGenerateToken)
        .then(res => {
          if (res?.status === 201 || res?.status === 200) {
            saveToken({
              access: res?.data?.data?.token?.access,
              refresh: res?.data?.data?.token?.refresh,
            });
            instance.defaults.headers.common["Authorization"] =
              "Bearer " + retrieveToken();
            return instance(originalRequest);
          } else {
            localStorage.clear();
            window.location.pathname = "/login";
          }
        })
        .catch(error => {
          //localStorage.clear();
          //window.location.pathname = "/login";
        });
    }

    return Promise.reject(error);
  }
);
export default instance;
