import { takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as ArticleMiddleWare from "./articleSagas";
import * as ServiceMiddleWare from "./service";
import * as CartMiddleWare from "./cartSagas";
import * as NotificationMiddleWare from "./notificationSagas";
import * as UserMiddleWare from "./userSagas";
import * as EinvitesMiddleware from "./einvitesSagas";

export default function* mySaga() {
  yield takeLatest(
    actionTypes.GET_ARTICLE_LIST,
    ArticleMiddleWare.getArticleSagaCall
  );
  yield takeLatest(
    actionTypes.GET_SERVICE_LIST,
    ServiceMiddleWare.getServiceListCall
  );
  yield takeLatest(
    actionTypes.GET_SIMILAR_CATEGORY_LIST,
    ServiceMiddleWare.getSimilarCategoryListCall
  );
  yield takeLatest(
    actionTypes.GET_COMMENT_LIST,
    ServiceMiddleWare.getCommentListCall
  );
  yield takeLatest(
    actionTypes.GET_CITY_LIST,
    ServiceMiddleWare.getCityListCall
  );
  yield takeLatest(
    actionTypes.GET_INVITE_LIST,
    ServiceMiddleWare.getInviteListCall
  );
  yield takeLatest(
    actionTypes.GET_NEXT_INVITE_LIST,
    ServiceMiddleWare.getNextInviteListCall
  );
  yield takeLatest(
    actionTypes.GET_STATE_LIST,
    ServiceMiddleWare.getStateListCall
  );
  yield takeLatest(
    actionTypes.GET_BUSINESS_LIST,
    ServiceMiddleWare.businessListCall
  );

  yield takeLatest(
    actionTypes.SEARCH_ARTICLE,
    ArticleMiddleWare.searchArticleSagaCall
  );

  yield takeLatest(actionTypes.GET_CART_ITEMS, CartMiddleWare.getCartItemsCall);

  yield takeLatest(
    actionTypes.DELETE_CART_ITEMS,
    CartMiddleWare.deleteCartItemCall
  );

  yield takeLatest(
    actionTypes.GET_NOTIFICATION,
    NotificationMiddleWare.getNotificationCall
  );

  yield takeLatest(
    actionTypes.GET_APPLICATION_DETAILS,
    UserMiddleWare.getApplicationDataCall
  );

  yield takeLatest(
    actionTypes.GET_USER_CARDS_LIST,
    EinvitesMiddleware.getUserCardsSagaCall
  );

  yield takeLatest(
    actionTypes.SEARCH_USER_CARD,
    EinvitesMiddleware.searchUserCardSagaCall
  );
}
