import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services"

export function* getNotificationCall(action) {
    try {
      const response = yield call(serviceCreator.getNotificationService);

      if (response.status === 200 || response.status === 201) {
        if (
          response.data.status_code === 200 ||
          response.data.status_code === 201
        ) {
          yield put(actionCreators.toggleMainLoader(false));
          yield put(actionCreators.notificationResponse(response.data.data));
        }else {
            yield put(actionCreators.toggleMainLoader(false));
            // toastr.light("", "", ToasterAction.toastrErrorOptions);
          }
        } else {
          yield put(actionCreators.toggleMainLoader(false));
        }
    } catch (error) {
       yield put(actionCreators.toggleMainLoader(false));
      
    }
  }