import * as ActionTypes from "./actionTypes";

export const loginSuccess = data => ({ type: ActionTypes.LOGIN_SUCCESS, data });

export const logoutSuccess = () => ({ type: ActionTypes.LOGOUT_SUCCESS });

export const getApplicationDetails = () => ({
  type: ActionTypes.GET_APPLICATION_DETAILS,
});

export const applicationDetailsResponse = data => ({
  type: ActionTypes.APPLICATION_DETAILS_RESPONSE,
  data,
});

export const toggleMainLoader = data => ({
  type: ActionTypes.SHOW_OR_HIDE_MAIN_LOADER,
  data,
});

export const toggleSecondLoader = data => ({
  type: ActionTypes.SHOW_OR_HIDE_SECOND_LOADER,
  data,
});

export const toggleSearchLoader = data => ({
  type: ActionTypes.SHOW_OR_HIDE_MAIN_SEARCH_LOADER,
  data,
});

export const pushNextURlAccess = data => ({
  type: ActionTypes.ADD_NEXT_URL_ACCESS,
  data,
});
