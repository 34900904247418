import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  next: '',
  previous:'',
  count: 0,
  results: []
};

export default (state = initialState, { type, data }) => {
  switch (type) {
      case actionTypes.SIMILAR_CATEGORY_LIST_RESPONSE:
      return updateObject(state, {
        next: data.next,
        previous : data.previous,
        count: data.count,
        results: [...data.results]
      });
    default:
      return state;
  }
};