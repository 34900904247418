import * as ActionTypes from "./actionTypes";

export const getServiceList = () => ({ type: ActionTypes.GET_SERVICE_LIST });

export const similarCategoryList = data => ({
  type: ActionTypes.GET_SIMILAR_CATEGORY_LIST,
  data,
});

export const serviceListResponse = data => ({
  type: ActionTypes.SERVICE_LIST_RESPONSE,
  data,
});

export const commentList = data => ({
  type: ActionTypes.GET_COMMENT_LIST,
  data,
});

export const commentListResponse = data => ({
  type: ActionTypes.COMMENT_LIST_RESPONSE,
  data,
});

export const getCityList = state => ({
  type: ActionTypes.GET_CITY_LIST,
  state,
});

export const getStateList = () => ({
  type: ActionTypes.GET_STATE_LIST,
});

export const getInviteList = () => ({
  type: ActionTypes.GET_INVITE_LIST,
});

export const getNextInviteList = (data) => ({
  type: ActionTypes.GET_NEXT_INVITE_LIST, data
});

export const cityListResponse = data => ({
  type: ActionTypes.CITY_LIST_RESPONSE,
  data,
});

export const stateListResponse = data => ({
  type: ActionTypes.STATE_LIST_RESPONSE,
  data,
});

export const inviteListResponse = data => ({
  type: ActionTypes.INVITE_LIST_RESPONSE,
  data,
});

export const getBusinessList = data => ({
  type: ActionTypes.GET_BUSINESS_LIST,
  data,
});

export const businessListResponse = data => ({
  type: ActionTypes.BUSINESS_LIST_RESPONSE,
  data,
});

export const similarCategoryListResponse = data => ({
  type: ActionTypes.SIMILAR_CATEGORY_LIST_RESPONSE,
  data,
});

export const pushBusinessDetail = data => ({
  type: ActionTypes.PUSH_BUSINESS_DETAIL,
  data,
});
