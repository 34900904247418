import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  mainLoader: false,
  searchLoader:false,
  secondLoader:false,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SHOW_OR_HIDE_MAIN_LOADER:
      return updateObject(state, {
        mainLoader: data
      });
    case actionTypes.SHOW_OR_HIDE_MAIN_SEARCH_LOADER:
      return updateObject(state, {
        searchLoader: data
      });

    case actionTypes.SHOW_OR_HIDE_SECOND_LOADER:
        return updateObject(state, {
          secondLoader: data
        });
    default:
      return state;
  }
};
