import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  links: {},
  count: 0,
  results: [],
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.USER_CARDS_LIST_RESPONSE:
      return updateObject(state, {
        links: data.links,
        count: data?.count || data?.results?.filter(obj => obj?.template_url !== null).length,
        results: [...data?.results?.filter(obj => obj?.template_url !== null)],
      });
    default:
      return state;
  }
};
