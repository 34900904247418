let apiVersion = process.env.REACT_APP_API_URL;

export const loginUser = `${apiVersion}/api/user/ v1/auth`;
export const signUpUser = `${apiVersion}/api/user/signup/`;
export const logoutUser = `${apiVersion}/api/user/logout`;
export const updateUserProfile = `${apiVersion}/api/user/updateUser/`;
export const forgotPassword = `${apiVersion}/api/user/userForgotPasswordRequest`;
export const resetPassword = `${apiVersion}/api/user/confirmPasswordRequest/`;
export const changePassword = `${apiVersion}/api/user/changePassword/`;
export const getArtistList = `${apiVersion}/api/article/getArticleList`;
export const getArtistListPaginationUrl = `${apiVersion}/api/article/getArticleList?page=`;
export const serviceList = `${apiVersion}/api/service/getServiceList`;
export const addCelebrityEnq = `${apiVersion}/api/enquiry/addCelebrityEnquiry`;
export const cityList = `${apiVersion}/api/user/getCityList`;
export const stateList = `${apiVersion}/api/user/getStateList`;
export const findService = `${apiVersion}/api/service/v1/searchServiceListByType/`;
export const articleLikeUrl = `${apiVersion}/api/article/likeArticle/`;
export const articleDisLikeUrl = `${apiVersion}/api/article/dislikeArticle`;
export const searchArticle = `${apiVersion}/api/article/searchArticle?search=`;
export const businessList = `${apiVersion}/api/service/v1/getServiceByTypeList/`;
export const likeService = `${apiVersion}/api/service/likeService`;
export const getCartItems = `${apiVersion}/api/service/getUserCart`;
export const deleteCartItem = `${apiVersion}/api/service/deleteCartItem/`;
export const addUserEnquiry = `${apiVersion}/api/enquiry/addUserEnquiry`;
export const addtoCartBtn = `${apiVersion}/api/service/addCartItem`;
export const getNotification = `${apiVersion}/api/user/getNotifications`;
export const getApplicationData = `${apiVersion}/api/user/getApplicationDetail`;
export const convertToken = `${apiVersion}/api/auth/oauth/convert-token/`;
export const generateToken = `${apiVersion}/api/auth/oauth/token/`;
export const manualGenerateToken = `${apiVersion}/api/user/generateToken/`;
export const getUserDetails = `${apiVersion}/api/user/v1/getUserDetails`;
export const getServiceShareUrl = `${apiVersion}/api/service/updateServiceShareUrl/`;
export const getCartShareUrl = `${apiVersion}/api/service/updateUserCartUrl/`;
export const getBusinessDetailObj = `${apiVersion}/api/service/getVendorServiceDetails/`;
export const allEinvites = `https://api.placid.app/api/rest/templates/?placid-custom-cors-header=*`;
export const allUserEinvites = user_id =>
  `${apiVersion}/api/e_invites/inviteTemplateList?user_id=${user_id}`;
export const singleUserInvite = template_id =>
  `${apiVersion}/api/e_invites/getInviteTemplate/${template_id}`;
export const addInvite = `${apiVersion}/api/e_invites/addInviteTemplate`;
export const userInviteTemplateSearch = (user_id, searchVal) =>
  `${apiVersion}/api/e_invites/inviteTemplateList?user_id=${user_id}&title=${searchVal}`;
export const getUserProfileDetail = `${apiVersion}/api/user/get_user_detail`;
export const getAllCategories = `${apiVersion}/api/pinterest/get_board_list`;
export const getAllCategoriesPins = (order, searchValue) =>
  `${apiVersion}/api/pinterest/get_all_pins?pin_title=${searchValue}&ordering=${order}`;
export const getBoardPinList = (order, searchValue) =>
  `${apiVersion}/api/pinterest/get_board_pin_list?pin_title=${searchValue}&ordering=${order}`;
export const verifyOTP = `${apiVersion}/api/user/v1/verifyUser`;
export const createProfile = `${apiVersion}/api/user/v1/createProfile`;
export const similarCategory = `${apiVersion}/api/service/v1/similarServiceList/`;
export const createReview = `${apiVersion}/api/feedbacks/v1/createReview/`;
export const listReview = `${apiVersion}/api/feedbacks/v1/listReview/`;
