import * as ActionTypes from "./actionTypes";

export const getUserCardList = data => ({
  type: ActionTypes.GET_USER_CARDS_LIST,
  data,
});

export const userCardListResponse = data => ({
  type: ActionTypes.USER_CARDS_LIST_RESPONSE,
  data,
});

export const searchCardItem = (data, payload) => ({
  type: ActionTypes.SEARCH_USER_CARD,
  data,
  payload,
});

export const updateSDKResponse = data => ({
  type: ActionTypes.SDK_RESPONSE,
  data
});